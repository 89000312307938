import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class HesToasterService {
  private readonly toaster = inject(ToastrService);
  private readonly translocoService = inject(TranslocoService);

  showBackendError(errorResponse: HttpErrorResponse) {
    if (errorResponse.status === 500) {
      this.showGlobalWrongMessage();
      return;
    }
    const error = errorResponse.error;
    const message = error.message || error?.error?.details[0]?.message;
    if (error?.messageRef) {
      const translation = this.translocoService.translate(error.messageRef);
      this.toaster.error(
        '',
        translation.length > 0 ? translation : error.message,
      );
    } else if (message) {
      this.toaster.error('', message);
    } else {
      this.showGlobalWrongMessage();
    }
  }

  success(message: string, title = '') {
    this.toaster.success(title, message);
  }

  error(message: string, title = '') {
    this.toaster.error(title, message);
  }

  showGlobalWrongMessage(message = '') {
    this.toaster.error(
      message,
      this.translocoService.translate('global.wrong_msg.title'),
    );
  }
}
